<template>
  <footer class="d-flex justify-space-between align-center pa-7 footer text-caption">
    <div>© {{ fullYear }} Меркурий онлайн</div>
    <div>Телефон: +7 909 407-85-81</div>
    <div
      type="button"
      @click="clickPolicy"
    >Политика обработки персональных данных
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    fullYear () {
      return new Date().getFullYear()
    }
  },
  methods: {
    clickPolicy () {
      this.$router.push({ path: 'privacy-policy' })
    }
  }
}
</script>

<style scoped>

</style>
