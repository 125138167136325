import { defaultImageUrl } from '@/config'

export default {
  methods: {
    thumbSrc (image, noEmptyThumb = false) {
      let locImage = noEmptyThumb ? null : defaultImageUrl
      if (image && image.path && image.file_name) {
        locImage = this.axios.defaults.baseURL + '/cache/images/thumbnails/' + image.path + image.file_name
      }
      return locImage
    },
    imageSrc (image, noEmptyThumb = false) {
      let locImage = noEmptyThumb ? null : defaultImageUrl
      if (image && image.path && image.file_name) {
        locImage = this.axios.defaults.baseURL + '/cache/images/' + image.path + image.file_name
      }
      return locImage
    }
  }
}
