<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
    <path d="M2.83022 19.7921L2.81444 19.7233L2.73678 19.6993C2.58785 19.6585 2.4958 19.5977 2.37298 19.5003C2.30572 19.447 2.23964 19.3723 2.15619 19.3497C2.00649 19.3092 1.8281 19.3152 1.67386 19.3135C1.31251 19.3095 0.921323 19.3413 0.564992 19.2837C0.385993 19.2547 0.231388 19.1118 0.129109 18.9567C0.0224667 18.7949 -0.0206595 18.5953 0.00928978 18.4007L0.0152761 18.3646C0.0480927 18.1531 0.10822 17.9028 0.270924 17.7605C0.378483 17.6665 0.493615 17.6411 0.628724 17.6299C1.21834 17.5808 1.83137 17.6172 2.42377 17.6161C3.57266 17.6138 4.72151 17.6103 5.8704 17.6097C6.78884 17.6091 7.70731 17.6088 8.62579 17.6089C9.16577 17.6089 9.73091 17.5734 10.2667 17.644L10.2632 19.8C8.72664 19.7535 7.18388 19.7416 5.64677 19.7558C4.97395 19.762 4.30099 19.7807 3.62812 19.7662C3.38264 19.7609 3.11097 19.7753 2.87161 19.7235C2.83336 19.7658 2.84608 19.7422 2.83022 19.7921Z" fill="#DEDDDD"/>
    <path d="M32.2697 17.6L32.2977 17.6054C32.4866 17.6322 32.71 17.6057 32.9038 17.6036C33.2976 17.5994 33.6915 17.6009 34.0852 17.6016C36.1989 17.605 38.3123 17.5995 40.4259 17.6009C41.1666 17.6014 41.9073 17.601 42.6478 17.6114C42.8924 17.6148 43.2873 17.5716 43.5051 17.6613L43.5297 17.6711C43.7311 17.7542 43.885 17.9042 43.9627 18.0996C44.0442 18.3042 43.98 18.5862 43.8849 18.7759C43.8188 18.9079 43.7276 19.0178 43.6093 19.1097L43.5865 19.128C43.345 19.3183 43.0807 19.3203 42.7841 19.3195C42.3953 19.3183 41.8984 19.2608 41.5265 19.3608C41.311 19.4187 41.1964 19.551 41.0095 19.6444C40.9395 19.6794 40.8075 19.6649 40.7817 19.7472L40.7775 19.791C40.6741 19.7509 40.4894 19.7771 40.3741 19.7788C40.0798 19.7834 39.7855 19.7876 39.4912 19.787C38.1285 19.7844 36.7665 19.7759 35.4038 19.7832C34.3725 19.7887 33.3309 19.8191 32.3006 19.7813C32.2488 19.6811 32.2747 19.4218 32.2727 19.3082C32.2625 18.739 32.2677 18.1693 32.2697 17.6Z" fill="#DEDDDD"/>
    <path d="M10.6886 7.32826L10.6693 7.3147C10.5676 7.26225 10.3845 7.29982 10.2609 7.26119L10.2331 7.25322C10.0185 7.18892 9.79867 6.96843 9.67379 6.77578L9.65451 6.74673C9.60117 6.66442 9.56687 6.5798 9.55119 6.48035C9.51147 6.22819 9.54951 5.94698 9.55241 5.69149C9.55508 5.45839 9.52742 5.20542 9.5613 4.9753C9.57431 4.88684 9.59929 4.80257 9.65264 4.73327L9.67205 4.70735C9.807 4.53084 9.98673 4.44929 10.1943 4.42816C10.8383 4.36255 11.5211 4.4333 12.1696 4.42569C14.1779 4.40208 16.1886 4.42342 18.197 4.43043C21.0064 4.44021 23.8168 4.44193 26.6262 4.42881C28.6747 4.41923 30.7253 4.3921 32.7737 4.40748C33.0316 4.4094 33.2894 4.41318 33.5473 4.41605C33.7107 4.41792 33.9072 4.40345 34.0596 4.47324L34.0815 4.48283C34.2288 4.54889 34.3465 4.68345 34.4068 4.84201C34.494 5.07152 34.483 6.29965 34.3916 6.52881C34.3681 6.58767 34.3305 6.63245 34.2963 6.68364L34.274 6.71955C34.0974 6.98275 33.7628 7.28782 33.4583 7.32408L33.2125 7.33336L21.3408 7.32262C19.322 7.28439 17.3009 7.3205 15.2818 7.31677C14.0775 7.3145 12.8734 7.31293 11.6692 7.31556C11.447 7.31606 11.2248 7.31248 11.0026 7.30945C10.9055 7.30814 10.7794 7.28777 10.6886 7.32826Z" fill="#DEDDDD"/>
    <path d="M29.5418 36.6666L29.5592 36.9653L29.5903 36.9616C29.9468 36.9215 30.3235 36.9312 30.6835 36.9293C31.3234 36.926 31.9632 36.9319 32.6029 36.9476C32.7303 36.9507 32.8566 36.9556 32.983 36.9699C33.0681 36.9796 33.1572 37.0011 33.243 36.9972L33.2631 36.9097C35.0843 36.8754 36.9061 36.8719 38.7276 36.8864C39.2253 36.8904 39.7229 36.8872 40.2205 36.9004C40.4527 36.9066 40.7423 36.8928 40.9574 36.9777L40.9917 36.9597C41.0699 36.9452 41.1409 36.9161 41.2205 36.9057C41.4402 36.877 41.725 36.8714 41.9447 36.9007L41.9828 36.9062L42.0505 36.9153C42.6093 37.0033 43.1912 37.4253 43.5012 37.8174L43.5256 37.8487C43.7135 38.087 43.857 38.3511 43.9237 38.6342C43.997 38.945 43.9857 39.2832 44 39.6L0.00335454 39.598C-0.0152456 39.1519 0.0405829 38.6926 0.244417 38.2788C0.522944 37.7133 1.0711 37.2221 1.7418 36.9958C2.08754 36.8792 2.56634 36.7845 2.92139 36.9202C2.97615 36.9411 3.02738 36.9565 3.08494 36.9711L3.13207 36.9452C3.19662 36.9203 3.24458 36.9102 3.31532 36.9076C4.65003 36.86 5.99326 36.894 7.32927 36.882C8.07109 36.8753 8.81272 36.8694 9.55458 36.8684C9.90866 36.8679 10.3464 36.8291 10.6894 36.8974L10.7174 36.9024C10.776 36.9134 10.7997 36.9302 10.8389 36.9681L10.8566 36.9574C11.0028 36.8796 11.735 36.9083 11.936 36.9067C12.4792 36.9023 13.0223 36.9003 13.5655 36.9048C13.8969 36.9076 14.2678 36.8901 14.5908 36.9574C14.8699 37.0204 15.1403 37.0982 15.3763 37.2458C15.4665 37.3023 15.6041 37.4305 15.7028 37.4615C15.7629 37.4803 15.8827 37.4663 15.9473 37.4661C16.1978 37.4657 16.4483 37.4642 16.6988 37.4652C18.2977 37.4719 19.9011 37.4563 21.4994 37.483C22.0515 37.4927 22.6051 37.4816 23.1574 37.4828C24.4308 37.4855 25.7026 37.486 26.9758 37.4692C27.2962 37.4649 27.6167 37.4656 27.9371 37.4654C28.0411 37.4654 28.1959 37.4834 28.2951 37.4654C28.3364 37.4579 28.4396 37.3806 28.4791 37.3578C28.8007 37.1717 29.1519 36.9944 29.5425 36.963L29.5418 36.6666Z" fill="#FFD84A"/>
    <path d="M33.7334 19.8121C34.6246 19.8487 35.5255 19.8193 36.4176 19.8139C37.5963 19.8069 38.7745 19.8151 39.9532 19.8176C40.2078 19.8182 40.4624 19.8141 40.7169 19.8097C40.8166 19.808 40.9764 19.7827 41.0658 19.8215L41.0667 36.6455L41.0342 36.6667C40.8305 36.5665 40.5563 36.5829 40.3365 36.5756C39.8654 36.5599 39.3942 36.5637 38.923 36.559C37.1983 36.5419 35.4733 36.546 33.7489 36.5864C33.7886 36.2064 33.7587 35.7911 33.7586 35.4074C33.7584 34.7459 33.7696 34.0863 33.7696 33.4244C33.7697 31.1981 33.7659 28.972 33.7633 26.7457C33.7614 25.1627 33.7595 23.5798 33.7592 21.997C33.7591 21.5174 33.7569 21.0379 33.754 20.5584C33.7525 20.3171 33.7715 20.0484 33.7334 19.8121Z" fill="#E83A17"/>
    <path d="M39.2471 21.2667L39.599 21.2708L39.6002 25.6667L35.5194 25.6401L35.2034 25.6429L35.2002 21.2881L39.2471 21.2667Z" fill="#FFD84A"/>
    <path d="M35.6213 27.8667L39.6002 27.8745C39.5991 29.0405 39.5807 30.2063 39.581 31.3721C39.5812 31.7425 39.5823 32.113 39.5839 32.4834C39.5845 32.6418 39.6039 32.8412 39.5754 32.993L39.2343 33L35.2002 32.9919L35.2006 27.8796L35.6213 27.8667Z" fill="#FFD84A"/>
    <path d="M10.497 7.35664C10.5859 7.31909 10.7094 7.33798 10.8045 7.3392C11.0221 7.342 11.2397 7.34532 11.4573 7.34485C12.6366 7.34242 13.8157 7.34387 14.995 7.34598C16.9723 7.34944 18.9515 7.31595 20.9285 7.3514C20.985 7.77675 20.9572 8.23492 20.9581 8.66457C20.9598 9.42067 20.9594 10.1768 20.9619 10.933L14.2721 10.9337L14.2889 15.0549L20.9562 15.0513L21.0057 19.5881L14.267 19.5832L14.2931 23.5927L20.9602 23.5613L21.0209 27.608C21.102 27.613 21.187 27.6129 21.2669 27.627C21.1969 27.6298 21.0279 27.616 20.9723 27.6535L20.9752 27.6842L21.0255 37.4C19.4529 37.3687 17.8752 37.387 16.3021 37.3791C16.0556 37.3779 15.8091 37.3797 15.5627 37.3802C15.4991 37.3804 15.3812 37.3969 15.3221 37.3747C15.225 37.3385 15.0896 37.1882 15.0008 37.122C14.7686 36.949 14.5026 36.8578 14.228 36.784C13.9102 36.7051 13.5452 36.7256 13.2191 36.7223C12.6847 36.717 12.1503 36.7194 11.6159 36.7245C11.418 36.7264 10.6977 36.6928 10.5538 36.784L10.5364 36.7966C10.4978 36.7521 10.4745 36.7324 10.4168 36.7195L10.3893 36.7137C10.0518 36.6336 9.6211 36.6791 9.27271 36.6797C8.54277 36.6808 7.81306 36.6878 7.08317 36.6956C5.76863 36.7097 4.44699 36.6698 3.13374 36.7256C3.06414 36.7286 3.01694 36.7405 2.95343 36.7697L2.93359 20.0985C2.94977 20.05 2.9368 20.0729 2.9758 20.0318C3.21984 20.0821 3.49683 20.0682 3.74711 20.0733C4.43316 20.0874 5.11929 20.0692 5.80529 20.0632C7.37249 20.0493 8.94545 20.0609 10.5121 20.1061L10.5156 18.0095L10.497 7.35664Z" fill="#E83A17"/>
    <path d="M4.48946 21.2748L8.06559 21.2667C8.06644 22.3119 8.06803 23.3571 8.06625 24.4022C8.06582 24.6713 8.06504 24.9404 8.06377 25.2095C8.06311 25.3508 8.07501 25.513 8.04942 25.6506L7.97896 25.6654L4.40039 25.6667C4.40396 24.6239 4.40869 23.5811 4.41058 22.5384C4.41105 22.2703 4.41 22.0022 4.40803 21.7341C4.40694 21.5883 4.395 21.433 4.41314 21.2892L4.48946 21.2748Z" fill="#FFD84A"/>
    <path d="M4.4377 27.8667L8.06706 27.9048L8.05226 32.9825L7.99414 33L4.40039 32.9837C4.42759 31.6165 4.4126 30.2394 4.41353 28.8714C4.41372 28.622 4.41263 28.3726 4.41128 28.1231C4.41105 28.0784 4.39985 27.9601 4.41004 27.921C4.41546 27.9001 4.42848 27.8848 4.4377 27.8667Z" fill="#FFD84A"/>
    <path d="M14.6734 36.754L14.667 27.1563L21.0365 27.1333C21.1125 27.1386 21.1921 27.1384 21.267 27.1533C21.2014 27.1562 21.0431 27.1417 20.991 27.1811L20.9937 27.2132L21.0408 37.4C19.5678 37.3671 18.0899 37.3863 16.6163 37.3781C16.3854 37.3768 16.1545 37.3786 15.9236 37.3792C15.8641 37.3793 15.7537 37.3966 15.6983 37.3734C15.6073 37.3354 15.4805 37.1779 15.3974 37.1085C15.1798 36.927 14.9307 36.8315 14.6734 36.754Z" fill="#FDFDFD"/>
    <path d="M21.5869 7.33337L33.6635 7.34334L33.6657 17.96C33.6638 18.5069 33.659 19.0541 33.6685 19.6009C33.6703 19.71 33.6462 19.9591 33.6946 20.0553C33.7357 20.2901 33.7152 20.5571 33.7168 20.7969C33.72 21.2733 33.7223 21.7498 33.7224 22.2263C33.7228 23.799 33.7249 25.3719 33.7269 26.9448C33.7297 29.157 33.7338 31.3689 33.7337 33.5811C33.7336 34.2388 33.7216 34.8942 33.7218 35.5515C33.7219 35.9328 33.7542 36.3455 33.7113 36.7231L33.6908 36.8257C33.603 36.8302 33.512 36.8051 33.425 36.7938C33.2958 36.7769 33.1667 36.7712 33.0366 36.7676C32.3827 36.7491 31.7288 36.7422 31.0747 36.7461C30.7067 36.7483 30.3217 36.7369 29.9574 36.784L29.9256 36.7883L29.9078 36.4381L29.9085 36.7856C29.5093 36.8224 29.1503 37.0303 28.8217 37.2485C28.7812 37.2753 28.6757 37.3659 28.6335 37.3747C28.5322 37.3958 28.374 37.3747 28.2677 37.3747C27.9402 37.3749 27.6126 37.3741 27.2851 37.3791C25.9837 37.3989 24.6839 37.3982 23.3824 37.3951C22.8178 37.3937 22.252 37.4067 21.6877 37.3954L21.6355 27.6752L21.6324 27.6445C21.6902 27.6069 21.8657 27.6208 21.9385 27.618C21.8555 27.6039 21.7671 27.604 21.6829 27.5989L21.6199 23.5505L14.6942 23.5819L14.667 19.5706L21.6672 19.5755L21.6157 15.0367L14.6898 15.0403L14.6723 10.9172L21.6217 10.9165C21.619 10.16 21.6194 9.40357 21.6177 8.64713C21.6167 8.21729 21.6456 7.75891 21.5869 7.33337Z" fill="#FC5B02"/>
    <path d="M21.1525 19.0729L28.5848 19.0667C28.5847 20.0651 28.5838 21.0636 28.5874 22.0621C28.5891 22.5148 28.5557 23.0159 28.6003 23.4621L21.1087 23.4322L14.6922 23.4667L14.667 19.0675L21.1525 19.0729Z" fill="#DEDDDD"/>
    <path d="M21.0949 15.3951L14.6832 15.3989L14.667 11.0007L21.1004 11L28.5637 11.0035L28.6003 15.4L21.0949 15.3951Z" fill="#DEDDDD"/>
    <path d="M21.5649 27.1459L29.3083 27.1333L29.3326 36.3916L29.3333 36.7558C28.9441 36.7944 28.5942 37.0124 28.2739 37.2411C28.2345 37.2692 28.1317 37.3642 28.0905 37.3734C27.9918 37.3955 27.8376 37.3734 27.7339 37.3734C27.4147 37.3737 27.0954 37.3728 26.7762 37.378C25.5078 37.3988 24.2408 37.3981 22.9722 37.3947C22.422 37.3933 21.8705 37.407 21.3205 37.3951L21.2696 27.2058L21.2666 27.1736C21.3229 27.1342 21.494 27.1488 21.5649 27.1459Z" fill="#DEDDDD"/>
  </svg>
</template>

<script>
export default {
  name: 'MultipleBuildings'
}
</script>
