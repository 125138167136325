<template>
  <v-app style="background: #FAFAFA">
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import '@/css/style.css'
import '@/css/rewrite.css'
import AccountLayout from './layouts/AccountLayout'
import PublicLayout from './layouts/PublicLayout'
import AuthLayout from '@/layouts/AuthLayout'

export default {
  name: 'app',
  computed: {
    layout () {
      return (this.$route.meta.layout)
    }
  },
  components: { AccountLayout, PublicLayout, AuthLayout }
}
</script>
