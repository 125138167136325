<template>
  <div class="grey lighten-5">
    <MessageSnackList/>
    <AppHeader/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import AppHeader from '../components/layouts/Common/AppHeader'
import Footer from '../components/layouts/Common/Footer'
import MessageSnackList from '@/components/layouts/Common/MessageSnackList'

export default {
  name: 'PublicLayout',
  data () {
    return {
      isOpenSnackbar: true
    }
  },
  components: { Footer, AppHeader, MessageSnackList },
  async mounted () {
    return this.$store.dispatch('serviceData/optionValues')
  },
  computed: {
    isLogged () {
      return !!this.$store.getters['auth/getProfile']
    }
  }
}
</script>
