import { render, staticRenderFns } from "./AccountLayout.vue?vue&type=template&id=30c5f6f2&scoped=true&"
import script from "./AccountLayout.vue?vue&type=script&lang=js&"
export * from "./AccountLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c5f6f2",
  null
  
)

export default component.exports