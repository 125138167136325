import Vue from 'vue'
import VueRouter from 'vue-router'
import publicRoutes from '@/router/public'
import accountRoutes from '@/router/account'
import store from '@/store'

Vue.use(VueRouter)

let routes = publicRoutes.concat(accountRoutes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const isNeedAuth = to.matched.some(route => route.meta.auth)
  const isLogged = !!store.getters['auth/getJwt']
  if (isNeedAuth && !isLogged) {
    // next({ name: 'login' })
    router.push({ name: 'login' }).catch(() => {
    })
  } else {
    const id = from.params.id
    if (id && !to.matched.some(route => route.meta.layout === 'AccountLayout') && to.name === 'sale') {
      let params = []
      if (to.hash) {
        params = to.hash.split('?')
      } else {
        params = to.fullPath.split('?')
      }
      let fromHash = '#card' + id
      if (params[0] !== fromHash) {
        if (params.length > 1) {
          fromHash += '?' + params[1]
        }
        const resolvedPath = router.resolve({ path: to.path })
        if (!resolvedPath.resolved.matched.length) {
          next({ name: 'notFound' })
        }

        next({
          path: resolvedPath.resolved.matched[0].path.replace('*', resolvedPath.resolved.fullPath) + fromHash
        })
      }
    }
    next()
  }
})

export default router
