export default {
  computed: {
    isMobile () {
      let mobile = false
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        mobile = true
      }
      return mobile
    },
    isMobileByWidth () {
      return this.width < 768
    },
    width () {
      return window.innerWidth
    }
  }
}
