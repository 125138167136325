import Qs from 'qs'
import { cleanClone, processGetData } from '@/components/utils/common'

function isExist (state, id) {
  return state.items.some(item => item.id === id)
}

function getUrlStringFromQueryObject (query) {
  return Qs.stringify(processGetData(cleanClone(query)), {
    arrayFormat: 'indices',
    encode: false
  })
}

export default {
  namespaced: true,
  state: {
    items: []
  },
  mutations: {
    add (state, data) {
      if (!isExist(state, data.id)) {
        state.items.push({
          id: data.id
        })
      }
    },
    remove (state, id) {
      if (isExist(state, id)) {
        const index = state.items.findIndex(item => item.id === id)
        if (index > -1) {
          state.items.splice(index, 1)
        }
      }
    },
    clear (state) {
      state.items = []
    }
  },
  actions: {
    async update ({ dispatch, getters, commit }) {
      let result = true
      let items = getters['ids']
      if (items.length) {
        let query = {
          filter: { advertId: items },
          pagination: {
            perPage: '20',
            pageNumber: 1
          },
          sort: {
            published_at: 'DESC'
          }
        }
        let response = await dispatch('server/get', { url: '/sale?' + getUrlStringFromQueryObject(query) }, { root: true })
        commit('clear')
        response.models.forEach(item => commit('add', item))
        result = response.models
      }
      return result
    }
  },
  getters: {
    isExist: (state) => (id) => isExist(state, id),
    all: state => state.items,
    ids: state => state.items.map(item => item.id)
  }
}
