<template>
  <div>
    <MessageSnack
      class="top-modifier"
      v-for="(message, index) in messages"
      :key="index"
      :message="message"
      :message-index="index"
    />
  </div>
</template>

<script>
import MessageSnack from '@/components/layouts/Common/MessageSnackList/MessageSnack'

export default {
  name: 'MessageSnackList',
  components: { MessageSnack },
  computed: {
    messages () {
      return this.$store.getters['systemMessages/getMessages']
    }
  }
}
</script>

<style scoped>

</style>
