/* eslint-disable import/first */
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)
import ru from 'vuetify/es5/locale/ru'
import MultipleBuildings from '@/components/icons/MultipleBuildings'
import YellowBuildings from '@/components/icons/YellowBuildings'
import Home from '@/components/icons/Home'

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  },
  icons: {
    iconfont: 'mdi',
    values: {
      multipleBuildings: {
        component: MultipleBuildings
      },
      yellowBuildings: {
        component: YellowBuildings
      },
      home: {
        component: Home
      }
    }
  },
  breakpoint: {
    thresholds: {
      smallXS: 340,
      xs: 599
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#1C9EC7',
        darkGreen: '#053F57'
      }
    }
  }
})
