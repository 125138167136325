<template>
  <v-snackbar
    class="top-modifier"
    v-model="localShow"
    :color="message.type"
    :timeout="timeout"
    right
    rounded="lg"
    top
    content-class="d-flex align-center"
  >
    <v-icon
      color="white"
      class="mr-2"
    >{{ icon }}
    </v-icon>
    {{ text }}
    <v-btn
      icon
      text
      @click="hideMessage"
      class="ml-auto"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'MessageSnack',
  props: {
    message: Object,
    messageIndex: Number,
    timeout: {
      type: Number,
      default: 4000
    }
  },
  data: function () {
    return {
      localShow: true
    }
  },
  computed: {
    text () {
      return this.message.text
    },
    icon () {
      const icons = {
        error: 'mdi-close-octagon',
        success: 'mdi-check-circle-outline',
        warning: 'mdi-alert'
      }
      return icons[this.message.type] || 'mdi-alert-circle-outline'
    }
  },
  watch: {
    localShow () {
      this.hideMessage()
    }
  },
  methods: {
    hideMessage () {
      this.$store.commit('systemMessages/hideMessage', this.messageIndex)
    }
  }
}
</script>

<style scoped>
  @media (max-width: 350px) {
    /deep/ .v-snack__wrapper {
      min-width: 200px
    }
  }
</style>
