import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import systemMessages from './systemMessages'
import serviceData from './serviceData'
import server from './server'
import favorites from './favorites'
import auth from '@/store/auth'
import api from '@/store/api'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      name: 'mercury_public',
      paths: ['auth.jwt', 'auth.profile', 'favorites.items']
      /* storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, {
          // secure: true,
          // httpOnly: true,
          sameSite: 'strict'
        }),
        removeItem: key => Cookies.remove(key)
      } */
    })
  ],
  modules: {
    auth,
    systemMessages,
    serviceData,
    server,
    favorites,
    api
  }
})
