export default [
  {
    path: '/',
    name: 'main',
    meta: {
      title: 'Купить квартиру',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('../views/Main/Main.vue')
  },
  {
    path: '/sale',
    name: 'sale',
    meta: {
      title: 'Купить квартиру',
      layout: 'PublicLayout',
      auth: false,
      sitemap: {
        lastmod: 'December 22, 2019',
        priority: 0.8,
        changefreq: 'daily'
      }
    },
    component: () => import('../views/Sale.vue')
  },
  {
    path: '/sale/detail/:id',
    name: 'saleDetail',
    meta: {
      title: 'Детали',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('../components/views/Sale/Detail.vue')
  },
  /*{
    path: '/lease',
    name: 'lease',
    meta: {
      title: 'Аренда недвижимости',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('../views/Lease.vue')
  },
  {
    path: '/lease/detail/:id',
    name: 'leaseDetail',
    meta: {
      title: 'Детали',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('../components/views/Sale/Detail.vue')
  },
  {
    path: '/commercial',
    name: 'commercial',
    meta: {
      title: 'Коммерческая недвижимость',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('../views/Commercial.vue')
  },
  {
    path: '/commercial/detail/:id',
    name: 'commercialDetail',
    meta: {
      title: 'Детали',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('../components/views/Sale/Detail.vue')
  },
  {
    path: '/services',
    name: 'services',
    meta: {
      title: 'Услуги',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('../views/Services.vue')
  },*/
  {
    path: '/jobs',
    name: 'jobs',
    meta: {
      title: 'Вакансии',
      layout: 'PublicLayout',
      auth: false,
      sitemap: {
        lastmod: 'January 06, 2021',
        priority: 0.8,
        changefreq: 'daily'
      }
    },
    component: () => import('../views/Jobs.vue')
  },
  {
    path: '/about',
    name: 'aboutUs',
    meta: {
      title: 'О нас',
      layout: 'PublicLayout',
      auth: false,
      sitemap: {
        lastmod: 'January 06, 2021',
        priority: 0.8,
        changefreq: 'daily'
      }
    },
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: {
      title: 'По вопросам сотрудничества',
      layout: 'PublicLayout',
      auth: false,
      sitemap: {
        lastmod: 'December 22, 2019',
        priority: 0.8,
        changefreq: 'daily'
      }
    },
    component: () => import('../views/Contacts.vue')
  },
  {
    path: '/manual-advert',
    name: 'ManualAdvert',
    meta: {
      title: 'Разместить объявление о продаже.',
      layout: 'PublicLayout',
      auth: false,
      sitemap: {
        lastmod: 'January 06, 2021',
        priority: 0.8,
        changefreq: 'daily'
      }
    },
    component: () => import('../views/ManualAdvert.vue')
  },
  {
    path: '/not-found',
    name: 'notFound',
    meta: {
      title: 'Страница не найдена',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('../components/views/Common/PageNotFound.vue')
  },
  {
    path: '/guest/sale/:id?',
    name: 'guestCreateAdvert',
    meta: {
      title: 'Создать объявление без публикации',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('../views/GuestAdvert.vue')
  },
  {
    path: '*',
    name: 'default',
    meta: {
      title: '',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('@/views/DefaultPage.vue')
  },
  {
    path: '*/detail/:id',
    name: 'defaultDetail',
    meta: {
      title: 'Детали',
      layout: 'PublicLayout',
      auth: false
    },
    component: () => import('../components/views/Sale/Detail.vue')
  }
]
