<template>
  <v-navigation-drawer
    :value="value"
    @input="$emit('input', $event)"
    width="500"
    fixed
    collapse-on-scroll
    temporary
    right
    class="ma-4 mt-0 mb-0 rounded-xl elevation-20 navigation pb-6"
    height="98vh"
    overlay-opacity="0.1"
  >
    <v-card
      class="header"
      elevation="0"
    >
      <div class="d-flex flex-row justify-space-between">
        <v-card-title class="font-weight-bold text-uppercase">
          Избранное
        </v-card-title>
        <v-icon
          @click="onclick"
          color="black"
        >mdi-close
        </v-icon>
      </div>
      <Controls/>
    </v-card>
    <div class="favorites-block">
      <FavoriteItems :is-open="value"/>
    </div>
  </v-navigation-drawer>
</template>

<script>
import FavoriteItems from './Favorites/FavoriteItems'
import Controls from './Favorites/Controls'

export default {
  name: 'Favorites',
  components: { Controls, FavoriteItems },
  props: {
    value: { type: Boolean }
  },
  methods: {
    onclick () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>
  .favorites-block {
    margin-top: 100px;
    overflow: auto;
  }
  .header {
    position: fixed;
    z-index: 101;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 5px;
  }
  .navigation {
    top: 1vh !important;
    overflow: auto;
  }
  @media (max-width: 550px) {
    .navigation {
      margin: 0 !important;
      height: 100% !important;
      border-radius: 0 !important;
      top: 0 !important;
    }
    .favorites-block {
      margin-top: 140px;
    }
  }
</style>
