<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="42"
    viewBox="0 0 43 42"
    fill="none"
  >
    <g clip-path="url(#clip0_1702_7702)">
      <path
        d="M17.6262 6.95909L17.6088 6.94559C17.5127 6.8918 17.2041 6.9188 17.0874 6.91629C16.2791 6.89914 15.4743 6.92367 14.667 6.92052L14.9021 6.73779C15.2665 6.47752 15.4757 6.12451 15.5421 5.69407C15.5816 5.43805 15.5884 5.1736 15.6594 4.92279C15.7595 4.56855 16.0263 4.23337 16.325 4.01211L16.4226 3.94793L16.4591 3.924C16.5928 3.838 16.7508 3.76855 16.9047 3.72501L16.9412 3.71402C17.3079 3.60901 17.7146 3.70171 18.0423 3.87701C18.3283 4.02999 18.5677 4.24273 18.7631 4.49491C18.79 4.52964 18.7767 4.51742 18.8091 4.5388C18.9816 4.4031 19.1531 4.27927 19.3469 4.17278C19.876 3.88213 20.5201 3.62591 21.126 3.53887C21.387 3.50139 21.6523 3.49937 21.9155 3.50011C22.1826 3.50085 22.4568 3.50622 22.7172 3.56867C22.9947 3.63521 23.2402 3.73003 23.5028 3.83361L24.7924 4.4497C25.3059 4.22667 25.858 4.16229 26.3999 4.35173L26.7036 4.47738C27.0455 4.61258 27.38 4.8064 27.7015 4.98337C27.7872 5.03051 27.9623 5.16207 28.0508 5.17655C28.0862 5.18236 28.2016 5.14207 28.2411 5.13345C28.511 5.07444 28.7624 5.03125 29.0359 5.09469L29.0643 5.10099C29.2078 5.13399 29.3428 5.20202 29.4666 5.27831L29.496 5.29599C29.7604 5.46085 29.9572 5.71678 30.0264 6.01393L30.0329 6.04019C30.0974 6.31858 30.0518 6.69986 30.0452 6.9882C29.3723 6.98096 28.6987 6.98032 28.0258 6.96657C27.6879 6.95968 27.35 6.94672 27.0121 6.93751C26.8599 6.93333 26.6431 6.90816 26.4989 6.94702C26.3358 6.99352 26.1113 6.97091 25.9402 6.96776C25.6241 6.96199 25.3084 6.95638 24.9923 6.95648C24.0468 6.95682 23.1007 6.9815 22.1555 6.96564C22.0722 7.01617 21.8935 6.99573 21.7918 6.99677C21.3837 7.00105 20.9752 6.99618 20.567 6.99391C19.862 6.98997 19.1579 6.9915 18.4528 6.98189C18.1836 6.9782 17.8919 7.0014 17.6262 6.95909Z"
        fill="#DFD8CF"
      />
      <path
        d="M22.3672 7.01881C23.2778 7.03483 24.1893 7.0099 25.1002 7.00955C25.4048 7.00946 25.7089 7.01513 26.0134 7.02095C26.1783 7.02414 26.3945 7.04698 26.5517 7L26.5672 26.5357C26.4172 26.6024 26.1186 26.5592 25.9442 26.5592C25.2354 26.5594 24.5268 26.557 23.8181 26.5539C23.5312 26.5526 23.2443 26.5505 22.9574 26.5519C22.7721 26.5527 22.5784 26.5442 22.3998 26.6L22.3707 26.5611L22.3672 7.01881Z"
        fill="#EE4E1B"
      />
      <path
        d="M2.78806 35.7L2.8079 36.3611L3.41253 36.3661L7.21302 36.3543L14.2771 36.3419C15.403 36.342 16.5288 36.3413 17.6547 36.3453C17.9401 36.3464 18.2255 36.3467 18.5109 36.3496C18.6512 36.351 18.8236 36.3698 18.9599 36.3461C19.212 36.3079 19.5492 36.3391 19.809 36.3386C20.618 36.337 21.4416 36.3673 22.2487 36.3289L25.1009 36.3411L25.0966 35.9244L25.1096 36.3416L36.337 36.3664L42.2861 36.3418C43.1258 36.3318 43.967 36.3422 44.8069 36.3447C45.2375 36.346 45.7038 36.3133 46.1282 36.3615C46.2542 36.3758 46.4016 36.4246 46.5052 36.4915C46.6925 36.6127 46.8277 36.7966 46.8603 36.9989C46.8895 37.1824 46.8209 37.3455 46.693 37.4906C46.5582 37.6426 46.386 37.7486 46.1637 37.7709C45.8178 37.8058 45.4432 37.7837 45.0946 37.784C44.3946 37.7845 43.6945 37.7843 42.9945 37.7844C40.7437 37.7844 38.493 37.7836 36.2423 37.7835C28.6049 37.7829 20.967 37.7655 13.3297 37.7813C9.83234 37.7886 6.33496 37.7827 2.83763 37.7812C1.75716 37.7807 0.676712 37.7828 -0.403772 37.7852C-0.947735 37.7863 -1.5245 37.8199 -2.065 37.7818C-2.22795 37.7703 -2.38907 37.7239 -2.51483 37.63C-2.70067 37.4912 -2.8284 37.238 -2.8329 37.0265C-2.83671 36.847 -2.74318 36.7017 -2.60327 36.5758C-2.30274 36.3055 -1.83178 36.3363 -1.43692 36.3413C-1.15674 36.3449 -0.877091 36.3526 -0.596898 36.3459L2.21388 36.3413L2.78402 36.3564L2.78806 35.7Z"
        fill="#7B8590"
      />
      <path
        d="M17.5211 7C17.8052 7.04286 18.117 7.01936 18.4047 7.0231C19.1585 7.03283 19.9112 7.03128 20.6649 7.03527C21.1012 7.03757 21.5379 7.04251 21.9742 7.03817C22.0829 7.03712 22.274 7.05783 22.3629 7.00664L22.3668 26.6C22.2052 26.5374 21.8635 26.5699 21.6825 26.5651C20.2854 26.528 18.8792 26.5501 17.4814 26.5608L17.4766 25.5871C17.4938 24.7398 17.4613 23.8912 17.4676 23.0435C17.4964 19.1842 17.4847 15.3243 17.4825 11.465C17.4819 10.4445 17.4797 9.42396 17.4812 8.40344C17.4816 8.1196 17.4822 7.83571 17.484 7.55187C17.4851 7.37275 17.4688 7.17358 17.5211 7Z"
        fill="#FFD43C"
      />
      <path
        d="M36.4259 25.4159L36.4259 19.621L41.9462 19.6L41.9672 36.3721L36.3672 36.4L36.4259 25.4159Z"
        fill="#FFD43C"
      />
      <path
        d="M-0.708905 36.3766L-0.733398 20.7063C-0.581925 20.5298 -0.345533 20.3914 -0.159442 20.2558C0.197631 19.9956 0.55438 19.7347 0.912732 19.4764C1.15534 19.3016 1.39946 19.1431 1.62258 18.941C2.41541 18.8875 3.20647 18.909 4.0003 18.9137C4.27398 18.9153 4.64685 18.8708 4.90931 18.9343C4.92206 19.0106 4.9437 19.0304 4.99508 19.0821C5.07612 19.0992 5.08147 19.0323 5.15329 18.9949C5.25702 19.1035 5.31313 19.2974 5.39675 19.4301C5.48915 19.5768 5.61532 19.7044 5.73151 19.8312L5.75195 19.8532C5.85586 19.9628 5.97 20.058 6.09039 20.1478C6.30853 20.3103 6.53508 20.4647 6.76058 20.6162C6.78336 20.6315 6.92333 20.7114 6.93197 20.7292C6.99324 20.8551 6.95365 34.9512 6.9579 36.3863L3.22705 36.4L2.6335 36.3942L2.61402 35.6285L2.61006 36.3887L2.05037 36.3712L-0.708905 36.3766ZM2.58675 31.0198L2.61402 35.6285L2.6335 36.3942L3.22705 36.4L3.23445 31.0399L2.61679 31.0234L2.58675 31.0198Z"
        fill="#EE4E1B"
      />
      <path
        d="M2.80743 36.382L2.7666 30.8C2.96894 30.8186 3.2508 30.7955 3.43471 30.8592L3.4666 35.6154L3.46197 36.4L2.80743 36.382Z"
        fill="#432915"
      />
      <path
        d="M6.2666 19.7063L6.28263 19.6801C6.33551 19.5612 6.28744 14.0234 6.29359 13.3L14.6013 13.3112C14.6144 16.2134 14.6024 19.1154 14.6038 22.0177C14.6042 22.9237 14.6045 23.8297 14.604 24.7358C14.6038 25.1577 14.6428 25.6285 14.5953 26.0457C14.5499 26.4867 14.5667 26.9426 14.5704 27.3859C14.5756 28.0163 14.5799 28.6465 14.5792 29.277C14.5769 31.1799 14.55 33.0878 14.6013 34.99C14.6133 35.4348 14.5572 35.9577 14.6666 36.3856L7.52891 36.4C7.52453 34.9529 7.56528 20.7388 7.50222 20.6118C7.49332 20.5939 7.34925 20.5133 7.32581 20.4979C7.0937 20.3452 6.86052 20.1895 6.63599 20.0256C6.51207 19.9351 6.3946 19.839 6.28764 19.7285L6.2666 19.7063Z"
        fill="#FFD43C"
      />
      <path
        d="M26.4792 25.8307C26.7113 25.7705 27.0299 25.8086 27.2714 25.8079C27.8918 25.806 28.5121 25.8083 29.1325 25.811C29.1718 25.722 29.2023 25.619 29.2507 25.536C29.3596 25.349 29.5767 25.2033 29.7385 25.0695C29.9705 24.8777 30.2024 24.6796 30.429 24.4807C30.7619 24.1884 31.072 23.8456 31.4384 23.6012C31.7164 23.4158 32.0246 23.2794 32.3409 23.1905L32.5322 23.1465C32.9391 23.0886 33.4131 23.0732 33.8152 23.1681C34.5699 23.3462 35.2656 23.8828 35.8628 24.3866C36.036 24.5327 36.19 24.6978 36.367 24.8406L36.3061 36.4L25.3431 36.3704L25.3305 35.8724L25.3346 36.3698L22.5497 36.3552C21.7616 36.401 20.9574 36.3649 20.1674 36.3668C19.9138 36.3674 19.5845 36.3301 19.3384 36.3757C19.2052 36.404 19.0369 36.3815 18.9 36.3799C18.6213 36.3765 18.3425 36.3761 18.0638 36.3748C16.9645 36.37 15.8652 36.3708 14.7658 36.3707C14.6601 35.9335 14.7144 35.3992 14.7028 34.9446C14.6531 33.0008 14.6792 31.0512 14.6813 29.1066C14.6821 28.4624 14.6779 27.8184 14.6729 27.1742C14.6693 26.7213 14.653 26.2553 14.697 25.8047L18.1053 25.8301C18.0917 25.5293 18.0694 25.1431 18.1173 24.8483L18.1214 24.8256L18.1256 25.8168C19.3493 25.8058 20.5804 25.7834 21.8035 25.8211C21.9619 25.826 22.2611 25.7929 22.4026 25.8566L22.4308 25.8963C22.6044 25.8394 22.7926 25.8481 22.9726 25.8472C23.2513 25.8458 23.53 25.8479 23.8086 25.8492C24.4971 25.8524 25.1855 25.8549 25.874 25.8547C26.0434 25.8547 26.3335 25.8987 26.4792 25.8307Z"
        fill="#DFD8CF"
      />
      <path
        d="M22.4015 30.1C23.1373 30.1536 23.9195 30.6275 24.4007 31.2505C24.7925 31.7577 25.0576 32.3799 25.1367 33.0484C25.1785 33.4015 25.1659 33.7676 25.1638 34.1233C25.1604 34.7074 25.1488 35.2925 25.1566 35.8765L25.1608 36.4L22.3902 36.3846C22.3897 34.911 22.3652 33.4388 22.3673 31.9653C22.3678 31.6088 22.3687 31.2522 22.3697 30.8957C22.3704 30.628 22.3611 30.3645 22.4015 30.1Z"
        fill="#BB2A0F"
      />
      <path
        d="M19.5882 36.397C19.5521 35.7801 19.575 35.1559 19.5824 34.5379C19.5873 34.1206 19.5551 33.7002 19.5727 33.2841C19.5972 32.7039 19.752 32.119 20.0001 31.6371C20.409 30.8427 21.0023 30.3498 21.7177 30.1555C21.9396 30.0952 22.1418 30.0881 22.3674 30.1147C22.3323 30.3783 22.3404 30.6408 22.3398 30.9075C22.339 31.2626 22.3382 31.6178 22.3377 31.973C22.3359 33.4409 22.3571 34.9075 22.3575 36.3755C21.6779 36.4236 20.9844 36.3857 20.3032 36.3876C20.0844 36.3883 19.8005 36.3492 19.5882 36.397Z"
        fill="#EE4E1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_1702_7702">
        <rect
          width="42"
          height="42"
          fill="white"
          transform="translate(0.666992)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'YellowBuildings'
}
</script>
