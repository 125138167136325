export default {
  namespaced: true,
  state: {
    options: null
  },
  mutations: {
    setOptions (state, { categoryChain, data }) {
      if (data.serviceOptions) {
        data = data.serviceOptions
      }
      if (categoryChain) {
        const categories = categoryChain.split('.')
        let categoryFound = state.options
        categoryFound.forEach(
          parentCategory => {
            if (parentCategory.name === categories[0]) {
              parentCategory.children.forEach(
                childrenCategory => {
                  if (childrenCategory.name === categories[1]) {
                    childrenCategory.serviceValues = data
                  }
                }
              )
            }
          })
      } else {
        state.options = data
      }
    }
  },
  actions: {
    async optionValues ({ getters, commit, dispatch }) {
      let response = getters['getProfile']
      if (!response) {
        response = await dispatch('api/get', { url: '/service-data/service-options' }, { root: true })
        if (response.success) {
          commit('setOptions', { categoryChain: null, data: response.data })
        }
      }
      return response.data
    },
    async getCmsPages ({ dispatch }, { perPage = 1000 }) {
      const response = await dispatch('api/get', { url: '/cms-page?filter[is_active]=1&pagination[perPage]=' + perPage }, { root: true })
      return response.data && Array.isArray(response.data?.models) ? response.data.models : null
    }
  },
  getters: {
    optionValue: (state, getters) => (categoryChain, optionId) => {
      let optionValue = null
      if (state.options && optionId) {
        const category = getters['optionsList'](categoryChain)
        if (category) {
          optionValue = category.find(value => value.id === optionId).value
        }
      }
      return optionValue
    },
    optionsList: (state) => (categoryChain = null, withEmpty = false) => {
      let result = state.options
      if (state.options && categoryChain) {
        const categories = categoryChain.split('.')
        let categoryFound = state.options
        categories.forEach(category => {
          if (categoryFound.children) {
            categoryFound = categoryFound.children
          }
          categoryFound = categoryFound.find(option => option.name === category)
        })
        result = categoryFound.serviceValues
        if (withEmpty) {
          result = [{ id: null, value: 'Не указано' }].concat(result)
        }
      }
      return result
    }
  }
}
