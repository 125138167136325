<template>
  <v-navigation-drawer
    :value="value"
    @input="$emit('input', $event)"
    fixed
    collapse-on-scroll
    temporary
    right
    width="100%"
    color="#1C9EC7"
  >
    <template #prepend>
      <AppHeader
        is-burger-version
        @closeNavigation="$emit('input', false)"
      />
    </template>
    <Items
      in-burger-menu
      @input="$emit('input', false)"
    />
  </v-navigation-drawer>
</template>

<script>
import Items from '@/components/layouts/Common/Header/Items.vue'

export default {
  name: 'Navigation',
  components: { AppHeader: () => import('./AppHeader'), Items },
  props: {
    value: { type: Boolean }
  },
  methods: {
    onclick () {
      this.$emit('input', false)
    }
  }
}
</script>

<style>

  .navigation li {
    list-style: none;
  }
  .navigation a {
    color: #fff !important;
    display: block;
    padding: 10px 10px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
  }
  .navigation a:hover, .navigation .active a {
    background: #1976d2;
  }

</style>
