import noPhoto from '@/assets/nophoto.jpg'
import * as Server from './server'

export const maxFileSize = 11 * 1024 * 1024
export const maxImageSize = 10 * 1024 * 1024

export const advertSources = {
  donRio: 22
}

export const advertStatuses = {
  active: 2,
  deleted: 5,
  sold: 6
}

export const phoneCallStatuses = {
  available: 65,
  unavailable: 66,
  notAnswer: 67
}

export const phoneStatuses = {
  existed: 61,
  substitute: 21,
  notCalled: 20,
  clear: 1485
}

export const defaultImageUrl = noPhoto

export const phoneMask = '8 ### ### ## ##'

export const server = Server
