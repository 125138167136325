import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import axios from '@/plugins/axios'
import vuetify from '@/plugins/vuetify'
import clipboard from '@/plugins/clipboard'
import uploader from '@/plugins/uploader'
import vueHeadful from 'vue-headful'
import lingallery from 'lingallery'
import VueYandexMetrika from 'vue-yandex-metrika'
import vuelidate from '@/plugins/vuelidate'

Vue.config.productionTip = false
Vue.prototype.$can = store.getters['auth/can']

Vue.use(VueYandexMetrika, {
  id: 71110711,
  defer: true,
  router: router,
  env: process.env.NODE_ENV
  // other options
})

Vue.component('lingallery', lingallery)
Vue.component('vue-headful', vueHeadful)

new Vue({
  router,
  store,
  vuetify,
  axios,
  uploader,
  clipboard,
  vuelidate,
  render: h => h(App)
}).$mount('#app')
