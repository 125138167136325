<template>
  <div>
    <MessageSnackList/>
    <AppHeader/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>

import AppHeader from '../components/layouts/Common/AppHeader'
import Footer from '../components/layouts/Common/Footer'
import MessageSnackList from '@/components/layouts/Common/MessageSnackList'

export default {
  name: 'AccountLayout',
  components: { MessageSnackList, Footer, AppHeader },
  async mounted () {
    await this.$store.dispatch('serviceData/optionValues')
    return this.$store.dispatch('auth/fillProfile')
  }
}
</script>

<style scoped>

</style>
