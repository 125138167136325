<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          x-small
          color="light-blue accent-4"
          elevation="0"
          height="64"
          class="white-text ProfileButton"
          v-on="on"
        >
          <v-avatar
            color="#CBF375"
            size="48"
            class="mr-2"
          >
            <img
              v-if="profile"
              :src="thumbSrc(profile.avatar)"
            >
          </v-avatar>
          <div
            v-if="profile"
            class="ProfileName"
          >
            {{profile.username}}
          </div>
        </v-btn>
      </template>
      <div class="ProfileMenu">
        <v-list>
          <v-list-item-group>
            <router-link
              class="ml-1"
              tag="li"
              active-class="active"
              :to="{name:'myAdverts'}"
            >
              <v-list-item-title>
                <a>Мои Объвления</a>
              </v-list-item-title>
            </router-link>
            <router-link
              class="ml-1"
              tag="li"
              active-class="active"
              :to="{name:'myProfile'}"
            >
              <v-list-item-title>
                <a>Мои Профиль</a>
              </v-list-item-title>
            </router-link>
            <li
              class="ml-1"
              @click="handleExit"
            >
              <v-list-item-title>
                <a>Выход
                  <v-icon small>
                    mdi-exit-to-app
                  </v-icon>
                </a>
              </v-list-item-title>
            </li>
          </v-list-item-group>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
import image from '@/components/mixins/image'

export default {
  mixins: [image],
  name: 'ProfileMenu',
  data () {
    return {
      profile: this.$store.getters['auth/getProfile']
    }
  },
  methods: {
    async handleExit () {
      return this.$store.dispatch('auth/logout')
    }
  }
}
</script>

<style>
  @media (max-width: 800px) and (min-width: 200px) {
    .ProfileName {
      display: none;
    }
    .ProfileButton {
      height: 56px !important;
    }
  }
  .ProfileMenu li {
    list-style: none;
  }
  .ProfileMenu a {
    color: black !important;
    display: block;
    padding: 10px 10px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
  }
</style>
