<template>
  <div>
    <v-app-bar
      flat-1
      elevation="0"
      class="color-header align-center"
      height="67"
    >
      <v-toolbar-title>
        <router-link
          tag="a"
          :to="{name: 'main'}"
        >
          <v-img
            contain
            :src="logoHeader"
            class="pr-1 logo-header pa-2"
          >
          </v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer v-if="isMobile"></v-spacer>
      <Items>
        <template #favorite>
          <v-badge
            class="mr-4"
            overlap
            color="red"
            :value="countFavorites"
            :content="countFavorites"
          >
            <v-btn
              @click.stop="isOpenFavorites = true"
              color="#fff"
              small
              icon
            >
              <v-icon>mdi-heart-outline</v-icon>
            </v-btn>
          </v-badge>
        </template>
      </Items>
      <!--      <router-link-->
      <!--        class="text-decoration-none"-->
      <!--        v-if="!isLogged"-->
      <!--        active-class="active"-->
      <!--        :to="{name:'login'}"-->
      <!--      >-->
      <!--        <v-btn-->
      <!--          small-->
      <!--          text-->
      <!--          dark-->
      <!--        >-->
      <!--          <div class="fast-link"> Вход</div>-->
      <!--        </v-btn>-->
      <!--      </router-link>-->
      <!--      <router-link-->
      <!--        class="text-decoration-none pl-0 mr-2"-->
      <!--        v-if="!isLogged"-->
      <!--        active-class="active"-->
      <!--        :to="{name:'registration'}"-->
      <!--      >-->
      <!--        <v-btn-->
      <!--          text-->
      <!--          small-->
      <!--          dark-->
      <!--        >-->
      <!--          <div class="fast-link">Регистрация</div>-->
      <!--        </v-btn>-->
      <!--      </router-link>-->

      <!--      <a-->
      <!--        class="text-decoration-none mr-2"-->
      <!--        href="https://www.instagram.com/merkuriy_rielt/"-->
      <!--        target="_blank"-->
      <!--      >-->
      <!--        <v-btn-->
      <!--          outlined-->
      <!--          small-->
      <!--          icon-->
      <!--          rounded-->
      <!--          color="white"-->
      <!--          class="instagram"-->
      <!--        >-->
      <!--          <v-icon-->
      <!--            small-->
      <!--            color="#fff"-->
      <!--          >-->
      <!--            mdi-instagram-->
      <!--          </v-icon>-->
      <!--        </v-btn>-->
      <!--      </a>-->
      <v-btn
        v-if="isMobile && !isBurgerVersion"
        dark
        elevation="0"
        icon
        @click="isOpenNavigation = true"
        class="pl-1 pr-1"
      >
        <v-icon color="accent-4">mdi-menu</v-icon>
      </v-btn>
      <v-btn
        v-if="isBurgerVersion"
        dark
        elevation="0"
        icon
        @click.stop="$emit('closeNavigation')"
        class="pl-1 pr-1"
      >
        <v-icon color="accent-4">mdi-close</v-icon>
      </v-btn>
      <ProfileMenu v-if="isLogged"/>
    </v-app-bar>
    <Navigation
      v-if="!isBurgerVersion && isOpenNavigation"
      v-model="isOpenNavigation"
    />
    <Favorites v-model="isOpenFavorites"/>
  </div>
</template>

<script>
import Navigation from './Navigation'
import Favorites from './Favorites'
import ProfileMenu from './ProfileMenu'
import imgMobile from '@/assets/logo-no-text.svg'
import imgDesktop from '@/assets/logo-mercury.svg'
import isLogged from '@/components/mixins/isLogged'
import Items from '@/components/layouts/Common/Header/Items'

export default {
  name: 'AppHeader',
  mixins: [isLogged],
  components: { Items, ProfileMenu, Favorites, Navigation },
  props: {
    isBurgerVersion: Boolean
  },
  data: () => ({
    isOpenNavigation: false,
    isOpenFavorites: false,
    isOpenRegistration: false
  }),
  computed: {
    countFavorites () {
      return this.$store.getters['favorites/all'].length
    },
    isMobile () {
      let mobile = false
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        mobile = true
      }
      return mobile || this.isBurgerVersion
    },
    logoHeader () {
      let imgHeader = imgDesktop
      if (this.isMobile) {
        imgHeader = imgMobile
      }
      return imgHeader
    }
  }
}
</script>

<style scoped>
  .instagram {
    color: white;
  }
  @media (max-width: 800px) and (min-width: 200px) {
    .logo-header {
      margin-right: 50%;
      height: 50px;
      width: 80px;
    }
  }
  .color-header {
    background: linear-gradient(180deg, #006B8D 0%, #1EA2CC 100%);
  }
</style>
