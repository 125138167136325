<template>
  <v-card
    elevation="0"
    class="pb-0 pt-0 transparent"
  >
    <v-card-text class="d-flex btns-controls pt-0">
      <Btn
        @click="handleClearFavorites"
        title="Очистить список"
        color="darkGreen"
        class="white--text"
        rounded
        small
      />
      <v-tooltip
        :value="showTooltip"
        top
      >
        <template
          v-slot:activator="{ on }"
        >
          <Btn
            @click="handleCopyIds"
            title="Скопировать айди номера"
            color="darkGreen"
            class="white--text"
            rounded
            small
          />
        </template>
        <span>Скопировано</span>
      </v-tooltip>
    </v-card-text>
  </v-card>
</template>

<script>
import Btn from '@/components/base/Btn'

export default {
  components: { Btn },
  name: 'Controls',
  data: () => ({
    showTooltip: false
  }),
  methods: {
    handleClearFavorites () {
      this.$store.commit('favorites/clear')
    },
    handleCopyIds () {
      let ids = this.$store.getters['favorites/ids'].join(',')
      if (!ids) {
        ids = ' '
      }
      this.$copyText(ids)
      this.showTooltip = true
      setTimeout(() => {
        this.showTooltip = false
      }, 1000)
    }
  }
}
</script>

<style scoped>
  .btns-controls {
    gap: 12px
  }
  @media (max-width: 450px) {
    .btns-controls {
      flex-direction: column;
      gap: 5px;
    }
  }
</style>
