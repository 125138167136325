export default [
  {
    path: '/account',
    redirect: { name: 'myProfile' },
    meta: {
      title: 'Мой профиль',
      layout: 'AccountLayout',
      auth: true
    }
  },
  {
    path: '/account/my-profile',
    name: 'myProfile',
    meta: {
      title: 'Мой профиль',
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('../views/account/Profile.vue')
  },
  {
    path: '/account/my-adverts',
    name: 'myAdverts',
    meta: {
      title: 'Мои объявления',
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('../views/account/MyAdverts.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    meta: {
      title: 'Регистрация',
      layout: 'AuthLayout',
      auth: false,
      sitemap: {
        lastmod: 'December 22, 2019',
        priority: 0.8,
        changefreq: 'daily'
      }
    },
    component: () => import('../views/Auth/Registration.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Вход',
      layout: 'AuthLayout',
      auth: false,
      sitemap: {
        lastmod: 'December 22, 2019',
        priority: 0.8,
        changefreq: 'daily'
      }
    },
    component: () => import('../views/Auth/Login.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    meta: {
      title: 'Политика конфиденциальности',
      layout: 'AuthLayout',
      auth: false
    },
    component: () => import('../views/Auth/PrivacyPolicy.vue')
  },
  {
    path: '/recovery-password',
    name: 'recoveryPassword',
    meta: {
      title: 'Восстановление пароля',
      layout: 'AuthLayout',
      auth: false
    },
    component: () => import('../views/Auth/RecoveryPassword.vue')
  },
  {
    path: '/account/my-adverts/detail/:id?',
    name: 'myAdvertsDetail',
    meta: {
      title: 'Детали',
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('../components/views/Account/MyAdvert/Detail.vue')
  }
]
