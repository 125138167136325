<template>
  <v-card
    :height="heightContent"
    class="d-flex flex-column pt-0 pb-0 elevation-0 rounded-xl"
    outlined
    @click="clickCard"
  >
    <div class="d-flex flex-row pa-0">
      <v-img
        :src="mainImage"
        max-width="30%"
        :height="heightContent === 133 ? 132 : heightContent"
        class="rounded-l-xl"
        :class="$vuetify.breakpoint.xs ? 'mr-4' : 'mr-6'"
      />
      <div class="pr-1 d-flex flex-column">
        <p class="ma-0 mt-2 mb-0 text-caption">ID {{ item.id }}</p>
        <p class="text-subtitle-1 text-uppercase font-weight-bold ma-0 description">
          {{ formatValue('building.district_custom', item.building.district_custom) }},
          {{ croppedHomeParam }}
          {{ item.area_common }} М<sup>2</sup>, Этаж {{ item.floor }} /{{ item.total_floors }}
        </p>
        <v-list-item-subtitle class="green--text text-h6 font-weight-bold text-subtitle-1">
          {{ formatPrice(item.price * 1000) }} РУБ
        </v-list-item-subtitle>
        <v-list-item-subtitle class="d-flex justify-space-between align-center actual pb-3 text-caption">
          актуально на: {{ formatTodayDate(item.updated_at) }}
          <Btn
            @click="handleRemoveFavorite"
            icon="mdi-trash-can-outline"
            color-icon="red"
            is-type-icon
            class="mr-2 bin"
            height="14"
            max-width="14"
            size-icon="20"
          />
        </v-list-item-subtitle>
      </div>
    </div>
  </v-card>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import image from '@/components/mixins/image'
import { defaultImageUrl } from '@/config'
import Btn from '@/components/base/Btn'
import isMobile from '@/components/mixins/isMobile'

export default {
  components: { Btn },
  mixins: [formatters, image, isMobile],
  name: 'FavoriteItem',
  props: {
    item: Object
  },
  methods: {
    clickCard () {
      return this.$router.push({ path: this.$route.path + '/sale/detail/' + this.item.id })
    },
    handleRemoveFavorite (e) {
      e.stopPropagation()
      this.$store.commit('favorites/remove', this.item.id)
    }
  },
  computed: {
    mainImage () {
      if (this.item.building.buildingImages.length) {
        let mainBuildingImage = this.item.building.buildingImages.find(item => item.is_main)
        if (!mainBuildingImage) {
          mainBuildingImage = this.item.advertImages.find(item => item.is_main)
        }
        return this.thumbSrc(mainBuildingImage.image)
      } else {
        return defaultImageUrl
      }
    },
    croppedHomeParam () {
      // обрезается часть описания, на случай если оно слишком длинная
      const title = this.item.room_count + ' - комн. ' + this.formatValue('advert.flat_type', this.item.flat_type)
      const maxAllowableLength = this.isMobileByWidth ? 20 : 20
      return title.length > maxAllowableLength ? title.slice(0, maxAllowableLength) + '...' : title
    },
    heightContent () {
      return this.width < 300 ? 180 : this.width < 500 ? 160 : 133
    }
  }
}
</script>

<style scoped>
  @media (max-width: 300px) {
    .actual {
      flex-direction: column !important;
      align-items: center !important;
    }
  }
  .description {
    line-height: 1.2;
  }
  .bin {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  /deep/ .v-image__image--cover {
    background-size: initial
  }
</style>
