import Vue from 'vue'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    jwt: null,
    profile: null
  },
  mutations: {
    setJwt (state, jwt) {
      if (jwt) {
        Vue.axios.defaults.headers['Authorization'] = jwt
      } else {
        delete Vue.axios.defaults.headers['Authorization']
      }
      state.jwt = jwt
    },
    setProfile (state, data) {
      state.profile = data
    }
  },
  actions: {
    async login ({ getters, commit, dispatch }, data) {
      const response = await dispatch('api/post', { url: '/login', data }, { root: true })
      if (response.success) {
        commit('setJwt', 'Bearer ' + response.data.jwt)
        commit('setProfile', response.data.profile)
        dispatch('systemMessages/success', 'Вы успешно вошли в систему', { root: true })
        await router.push({ name: 'myAdverts' })
      }
      return response.data
    },
    async logout ({ commit, dispatch }) {
      const response = await dispatch('api/get', { url: '/logout' }, { root: true })
      if (response.success) {
        commit('setJwt', null)
        commit('setProfile', null)
        await router.push({ name: 'sale' })
      }
      return response.data
    },
    async changePassword ({ dispatch }, { data }) {
      const response = await dispatch('api/post', { url: '/user/change-password', data }, { root: true })
      if (response.success) {
        dispatch('systemMessages/success', 'Пароль успешно изменен', { root: true })
      }
      return response.data
    },
    async isUnique ({ dispatch }, { data }) {
      let response = await dispatch('api/post', { url: '/user-identity/check-unique', data: { data } }, { root: true })
      return response.data
    },
    async saveProfile ({ commit, dispatch }, { data }) {
      const response = await dispatch('api/post', { url: '/user/profile', data: { data } }, { root: true })
      if (response.success) {
        commit('setProfile', response.data)
        dispatch('systemMessages/success', 'Данные успешно сохранены', { root: true })
      }
      return response.data
    },
    async signUp ({ dispatch }, data) {
      const response = await dispatch('api/post', { url: '/sign-up', data }, { root: true })
      return response.data
    },
    async fillProfile ({ getters, commit, dispatch }) {
      let response = getters['getProfile']
      if (!response) {
        response = await dispatch('api/get', { url: '/user/profile' }, { root: true })
        if (response.success) {
          commit('setProfile', response.data)
        }
      }
      return response
    },
    async resetPassword ({ dispatch }, email) {
      const response = await dispatch('api/post', { url: '/reset-password', data: { data: { email } } }, { root: true })
      return response.data
    }
  },
  getters: {
    getJwt (state) {
      return state.jwt
    },
    getName (state) {
      return state.profile ? state.profile.username : state.profile
    },
    getRole: state => state.profile ? state.profile.role : state.profile,
    getProfile (state) {
      return state.profile
    },
    can: state => (role, name = null) => {
      let result = false
      if (state.profile) {
        if (state.profile.role === 'admin') {
          result = true
        } else if (state.profile.role === role) {
          if (!name || (name && state.profile.customPermissions.some(permission => permission === name))) {
            result = true
          }
        }
      }

      return result
    }
  }
}
