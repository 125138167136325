<template>
  <div
    :class="inBurgerMenu ? 'd-flex flex-column pa-2 pt-5' : 'd-flex flex-row align-center'"
    style="width: 100%"
  >
    <div
      v-if="!isMobile || inBurgerMenu"
      :class="inBurgerMenu ? 'd-flex flex-column' : 'd-flex flex-row align-center'"
    >
      <router-link
        :class="inBurgerMenu ? '' : 'fast-link'"
        class="text-decoration-none"
        tag="a"
        :to="{name: item.title}"
        v-for="item in items"
        :key="item.title"
      >
        <Btn
          text
          class="weight-900 white--text"
          @click="clickLink(item.title)"
          :title="item.text"
        />
      </router-link>
    </div>

    <v-spacer v-if="!inBurgerMenu"></v-spacer>
    <slot name="favorite"></slot>
    <router-link
      :to="{name: 'guestCreateAdvert' }"
      :class="inBurgerMenu ? 'pl-2 pt-3' : ''"
    >
      <Btn
        elevation="0"
        color="darkGreen"
        class="white--text mr-2 pa-4 pt-5 pb-5 rounded-lg weight-900"
        v-if="!isMobile || inBurgerMenu"
        @click="clickLink('guestCreateAdvert')"
        :class="inBurgerMenu ? '' : 'fast-link'"
        title="Разместить объявление"
      />
      <v-icon
        v-if="!inBurgerMenu && isMobile"
        color="white"
      >
        mdi-plus
      </v-icon>
    </router-link>
  </div>
</template>

<script>
import imgMobile from '@/assets/logo-no-text.svg'
import imgDesktop from '@/assets/logo-mercury.svg'
import isLogged from '@/components/mixins/isLogged'
import Btn from '@/components/base/Btn'

export default {
  name: 'Items',
  mixins: [isLogged],
  components: { Btn },
  props: {
    inBurgerMenu: Boolean
  },
  data: () => ({
    isOpenNavigation: false,
    isOpenFavorites: false,
    isOpenRegistration: false,
    items: [
      { text: 'Объявления', title: 'sale' },
      { text: 'О нас', title: 'aboutUs' },
      { text: 'Вакансии', title: 'jobs' },
      { text: 'Контакты', title: 'contacts' }
    ]
  }),
  computed: {
    countFavorites () {
      return this.$store.getters['favorites/all'].length
    },
    isMobile () {
      let mobile = false
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        mobile = true
      }
      return mobile
    },
    logoHeader () {
      let imgHeader = imgDesktop
      if (this.isMobile) {
        imgHeader = imgMobile
      }
      return imgHeader
    }
  },
  methods: {
    clickLink (link) {
      if (this.$route.name === link) {
        return this.$emit('input', false)
      }
    }
  }
}
</script>

<style scoped>
  @media (max-width: 800px) and (min-width: 200px) {
    .fast-link {
      display: none
    }
  }
</style>
