<template>
  <v-btn
    :disabled="disabled"
    :color="color"
    :small="small"
    :text="text"
    :icon="isTypeIcon"
    :x-small="xSmall"
    @click.stop="$emit('click', $event)"
    :loading="loading"
    :to="to"
    :class="classBtn"
    :width="width"
    :depressed="depressed"
    elevation="0"
    :title="tip"
    :rounded="rounded"
    :outlined="outlined"
    :max-width="maxWidth"
    :height="height"
  >
    {{ positionIcon === 'right' ? title : '' }}
    <v-icon
      v-if="icon"
      :class="title && positionIcon === 'left' ? 'mr-2' : title && positionIcon === 'right' ? 'ml-2' : ''"
      :x-small="xSmallIcon"
      :color="colorIcon"
      :small="isSmallIcon"
      :size="sizeIcon"
    >{{ icon }}
    </v-icon>
    {{ positionIcon === 'left' ? title : '' }}
  </v-btn>
</template>

<script>
export default {
  name: 'BaseBtn',
  props: {
    sizeIcon: String,
    icon: String,
    color: String,
    height: String,
    title: String,
    maxWidth: String,
    tip: String,
    small: Boolean,
    xSmall: Boolean,
    isSmallIcon: Boolean,
    xSmallIcon: Boolean,
    disabled: Boolean,
    text: Boolean,
    isTypeIcon: Boolean,
    loading: {
      type: Boolean,
      default: false
    },
    to: Object,
    classBtn: String,
    width: String,
    depressed: Boolean,
    colorIcon: String,
    outlined: Boolean,
    rounded: Boolean,
    positionIcon: {
      type: String,
      default: 'left'
    }
  }
}
</script>

<style scoped>

</style>
