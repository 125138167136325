export default {
  namespaced: true,
  state: {
    messages: []
  },
  mutations: {
    addError (state, msg) {
      state.messages.push({
        text: msg,
        type: 'error'
      })
    },
    addSuccess (state, msg) {
      state.messages.push({
        text: msg,
        type: 'success'
      })
    },
    hideMessage (state, index) {
      state.messages.splice(index, 1)
    }
  },
  actions: {
    success ({ commit }, msg) {
      commit('addSuccess', msg)
    },
    error ({ commit }, msg) {
      commit('addError', msg)
    },
    hideMessage ({ commit }, msg) {
      commit('hideMessage', msg)
    },
    async handleError ({ dispatch }, e) {
      try {
        if (e && e.response && e.response.status === 401) {
          await dispatch('auth/logout', {}, { root: true })
        } else {
          dispatch('systemMessages/error', 'Не удалось загрузить данные, повторите попытку позже', { root: true })
        }
      } catch (error) {}
      return true
    }
  },
  getters: {
    getMessages (state) {
      return state.messages
    }
  }
}
