import router from '@/router'

// Полное удаление реактивности
export function cleanClone (obj) {
  return obj ? JSON.parse(JSON.stringify(obj)) : obj
}

// Проверяем равенство массивов и объектов
export function deepEqual (entity1, entity2) {
  return JSON.stringify(entity1) === JSON.stringify(entity2)
}

export function collectMenuItems (config, showIcon) {
  let menu = []
  config.forEach(configItem => {
    let menuItem = {}
    if (configItem.name) {
      const route = router.resolve({ name: configItem.name })
      if (route) {
        menuItem = {
          title: route.route.meta.title,
          link: route.route.fullPath
        }
      }
    } else {
      menuItem = {
        title: configItem.title,
        subitems: collectMenuItems(configItem.subitems)
      }
    }
    menu.push(menuItem)
  })
  return menu
}

export function processGetData (rawData) {
  let data = {
    pagination: {
      pageNumber: rawData.pagination.pageNumber,
      perPage: rawData.pagination.perPage
    }
  }

  if (rawData.filter) {
    data.filter = {}
    for (let key in rawData.filter) {
      if (rawData.filter.hasOwnProperty(key)) {
        if (Array.isArray(rawData.filter[key])) {
          if (rawData.filter[key].length && !deepEqual(rawData.filter[key], [null, null])) {
            data.filter[key] = rawData.filter[key]
          }
        } else if (rawData.filter[key] !== null) {
          if (typeof rawData.filter[key] === 'object') {
            if (rawData.filter[key].hasOwnProperty('id')) {
              data.filter[key.replace('Filter', 'Id')] = rawData.filter[key].id
            } else {
              data.filter[key] = rawData.filter[key]
            }
          } else {
            data.filter[key] = rawData.filter[key]
          }
        }
      }
    }
  }

  if (rawData.sort) {
    data.sort = rawData.sort
  }
  return data
}

// доступ к свойствам объекта obj через строку path типа 'prop1.prop2.prop3'
export function recLookup (obj, path) {
  const parts = path.split('.')
  if (parts.length === 1) {
    return obj ? obj[parts[0]] : null
  }
  return recLookup(obj.hasOwnProperty(parts[0]) ? obj[parts[0]] : null, parts.slice(1).join('.'))
}

export function iterateOnObject (refObject, iterObject) {
  let sum = 0
  if (iterObject) {
    for (let key in refObject) {
      if (refObject.hasOwnProperty(key) && iterObject.hasOwnProperty(key)) {
        if (typeof refObject[key] === 'object' && refObject[key] !== null) {
          if (key === 'building' && iterObject[key]['is_verified']) {
            sum += iterateOnObject(refObject[key], refObject[key])
          } else {
            sum += iterateOnObject(refObject[key], iterObject[key])
          }
        } else {
          if (Array.isArray(iterObject[key])) {
            if (iterObject[key].length) {
              sum += refObject[key]
            }
          } else {
            if (iterObject[key] && iterObject[key] !== '0.00') {
              sum += refObject[key]
            }
          }
        }
      }
    }
  }
  return sum
}
