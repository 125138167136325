<template>
  <div>
    <MessageSnackList/>
    <AppHeader/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import MessageSnackList from '@/components/layouts/Common/MessageSnackList'
import Footer from '../components/layouts/Common/Footer'
import AppHeader from '@/components/layouts/Common/AppHeader'

export default {
  name: 'AuthLayout',
  components: { AppHeader, Footer, MessageSnackList }
}
</script>

<style scoped>

</style>
