import * as Config from '@/config'

export default {
  methods: {
    formatValue (categoryChain, valueId) {
      return this.$store.getters['serviceData/optionValue'](categoryChain, valueId)
    },
    timestampToDatetime (timestamp) {
      let date = new Date(timestamp * 1000)
      return date.toLocaleString('ru-RU')
    },
    formatTodayDate (timestamp) {
      let rez = new Date(timestamp * 1000).toLocaleDateString('ru-RU')
      if (timestamp !== null) {
        const createAt = new Date(timestamp * 1000).toLocaleDateString('ru-RU')
        if (createAt === new Date().toLocaleDateString('ru-RU')) {
          rez = 'Сегодня'
        }
      } else {
        rez = 'Недавно'
      }
      return rez
    },
    timestampToDate (timestamp) {
      let date = new Date(timestamp * 1000)
      return date.toLocaleDateString('ru-RU')
    },
    dateToTimestamp (date) {
      return Date.parse(date) / 1000
    },
    maskValue (value, pattern) {
      if (value) {
        let i = 1
        let v = value.toString()
        return pattern.replace(/#/g, _ => v[i++])
      }
    },
    advertClassByStatus (advert) {
      let resultClass = ''
      if (advert.delay_until) {
        resultClass = 'delay-until'
      } else {
        switch (advert.adv_status) {
          case Config.advertStatuses.deleted:
            resultClass = 'adv-deleted'
            break
          case Config.advertStatuses.active:
            resultClass = 'adv-active'
            break
          case Config.advertStatuses.sold:
            resultClass = 'adv-sold'
        }
      }
      return resultClass
    },
    phoneCallClass (status) {
      let resultClass = ''
      switch (status) {
        case Config.phoneCallStatuses.available:
          resultClass = 'statusGreen'
          break
        case Config.phoneCallStatuses.unavailable:
          resultClass = 'statusRed'
          break
        case Config.phoneCallStatuses.notAnswer:
          resultClass = 'statusYellow'
      }
      return resultClass
    },
    phoneStatusClass (status) {
      let resultClass = ''
      switch (status) {
        case Config.phoneStatuses.existed:
          resultClass = 'statusGreen'
          break
        case Config.phoneStatuses.substitute:
          resultClass = 'statusRed'
          break
        case Config.phoneStatuses.notCalled:
          resultClass = 'statusYellow'
      }
      return resultClass
    },
    formatPriceInput (price) {
      if ((price + '').match(/[^0-9]/g)) {
        price = (price + '').replace(/[^0-9]/g, '')
      }
      return this.formatPrice(price)
    },
    formatPrice (price) {
      let formatter = new Intl.NumberFormat('ru')
      return formatter.format(price)
    },
    reformatPrice (price) {
      if (price !== null) {
        return parseInt(price.replace(/\xA0/g, ''))
      }
    },
    bytesToSize (bytes, seperator = '') {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes}${seperator}${sizes[i]}`
      return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`
    }
  },
  computed: {
    formatTitleAdvert () {
      return this.detail.room_count + ' - комн. ' +
        this.formatValue('advert.flat_type', this.detail.flat_type) + ' - ' +
        this.detail.area_common + ' м2  ' + this.detail.building.mainBuildingAddress.street.name
    }
  }
}
