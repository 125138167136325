export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async autocomplete ({ dispatch }, { url, queryString }) {
      const response = await dispatch('api/get', { url: url + `?q=${queryString}` }, { root: true })
      return response.data
    },
    async get ({ dispatch }, { url, params }) {
      const response = await dispatch('api/get', { url }, { root: true })
      return response.data
    },
    async save ({ dispatch }, { url, data }) {
      const response = await dispatch('api/post', { url, data }, { root: true })
      if (response.success) {
        await dispatch('systemMessages/success', 'Данные успешно сохранены', { root: true })
      }
      return response.data
    },
    async delete ({ dispatch }, { url, data }) {
      const response = await dispatch('api/delete', { url, data }, { root: true })
      if (response.success) {
        dispatch('systemMessages/success', 'Данные успешно удалены', { root: true })
      }
      return response.data
    }
  },
  getters: {}
}
