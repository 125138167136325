<template>
  <div v-if="items.length">
    <div
      class="row-cards pa-2"
      v-for="item in items"
      :key="item.id"
    >
      <FavoriteItem :item="item"/>
    </div>
  </div>
</template>

<script>
import FavoriteItem from './FavoritItems/FavoriteItem'

export default {
  name: 'Favorite',
  props: {
    isOpen: Boolean
  },
  data () {
    return {
      items: []
    }
  },
  components: { FavoriteItem },
  watch: {
    isOpen: {
      async handler () {
        if (this.isOpen === true) {
          this.items = await this.$store.dispatch('favorites/update')
        }
        return true
      },
      immediate: true
    },
    favoriteItems: {
      async handler (newVal) {
        if (newVal.length !== this.items.length) {
          this.items = await this.$store.dispatch('favorites/update')
        }
        return true
      },
      deep: true
    }
  },
  computed: {
    favoriteItems () {
      return this.$store.getters['favorites/all']
    }
  }
}
</script>

<style scoped>

</style>
